
.login-main {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
  &,& * {
    box-sizing: border-box;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .login-box {
    background-color: #fff;
    width: 358px;
    border-radius: 15px;
    padding: 0 50px;
    .login-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .linear-color {
        width: 100%;
        background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
        color: #FFF;
    }
  }
}
